jQuery(document).ready(function($) {
	// Mobile menu
	$(".mobile-navigation-menu").mmenu({
		extensions: [
			"position-right"
		],
		navbar: {
			add: true,
			title: ""
		},
		screenReader: {
			aria: true,
			text: true
		}
	});

	$(".mobile-menu-button").click(function() {
		$(".mobile-navigation-menu").data( "mmenu" ).open();
	});

	// Fit Vids
	if ($("body").innerWidth() <= 767 ) {
		$(".inside-page-section").fitVids({ ignore: ".nofit" });
		$(".homebox").fitVids({ ignore: ".nofit" });
	}

	// Homepage
	$( ".usesblockholder .usesblock .div-block-4 .div-block-5" ).each(function() {
		if (!$(this).is(':empty')) {
			$( this ).prev().addClass( "with-background" );
			$( this ).next().addClass( "with-background" );
		}
	});

	// Webflow
	! function(o, c) {
		var n = c.documentElement,
			t = " w-mod-";
		n.className += t + "js", ("ontouchstart" in o || o.DocumentTouch && c instanceof DocumentTouch) && (n.className += t + "touch")
	}(window, document);

	/* eslint-disable */
	Webflow.require('ix').init([
		{"slug":"star-spin","name":"Star Spin","value":{"style":{},"triggers":[{"type":"hover","selector":".titlestar","descend":true,"loopA":true,"loopB":true,"preserve3d":true,"stepsA":[{"transition":"transform 1000ms ease 0","rotateX":"0deg","rotateY":"0deg","rotateZ":"180deg"}],"stepsB":[{"transition":"transform 1000ms ease 0","rotateX":"0deg","rotateY":"0deg","rotateZ":"-180deg"}]}]}},
		{"slug":"left-nav-red-diamond","name":"Left Nav Red Diamond","value":{"style":{},"triggers":[{"type":"hover","selector":".left-nav-red-diamond","descend":true,"stepsA":[{"opacity":1,"transition":"opacity 200 ease 0"}],"stepsB":[{"opacity":0,"transition":"opacity 200 ease 0"}]}]}},
		{"slug":"button-slide-right","name":"Button Slide Right","value":{"style":{},"triggers":[{"type":"hover","stepsA":[{"transition":"transform 450ms ease-out-quad 0","x":"5px","y":"0px","z":"0px"}],"stepsB":[{"transition":"transform 450ms ease-out-quad 0","x":"0px","y":"0px","z":"0px"}]}]}}
	]);
	/* eslint-enable */
});
